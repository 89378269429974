import './TreeMultiSelector.scss';
import {
  getAllIds,
  type TreeNode,
} from './TreeMultiSelector';
import { TreeMultiSelectorOption } from './TreeMultiSelectorOption/TreeMultiSelectorOption';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  type FC,
  useEffect,
  useState,
} from 'react';

type NestedCheckboxListProps = {
  childDataSelectionValue: string,
  childStyle: string,
  onCheck: (childrenNodes: string[]) => void,
  parentDataSelectionValue: string,
  parentStyle: string,
  selectedData: TreeNode[],
  treeData: TreeNode[],
};

export const NestedCheckboxList:
FC<NestedCheckboxListProps> = (props) => {
  const { childDataSelectionValue,
    childStyle,
    onCheck,
    parentDataSelectionValue,
    parentStyle,
    selectedData,
    treeData } = props;

  const { childrenNodes: initialSelectedChildren,
    parents: initialSelectedParents } = getAllIds(selectedData);

  const [
    selectedParent,
    setSelectedParent,
  ] = useState<Set<string>>(initialSelectedParents);

  const [
    selectedChildren,
    setSelectedChildren,
  ] = useState<Set<string>>(initialSelectedChildren);

  const [
    expandedParents,
    setExpandedParents,
  ] = useState<Set<string>>(new Set());

  useEffect(() => {
    const { childrenNodes,
      parents } = getAllIds(selectedData);
    setSelectedParent(parents);
    setSelectedChildren(childrenNodes);
  }, [
    selectedData,
  ]);

  useEffect(() => {
    const parentIds = treeData.map((parent) => parent.id);
    const totalChildren = treeData
      .reduce((acc, parent) => acc + (parent.children?.length || 0), 0);

    if (parentIds.length + totalChildren < 16) {
      setExpandedParents(new Set(parentIds));
    }
  }, [
    treeData,
  ]);

  const toggleExpand = (parentId: string, event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setExpandedParents((prevExpandedParents) => {
      const newExpandedParents = new Set(prevExpandedParents);
      if (newExpandedParents.has(parentId)) {
        newExpandedParents.delete(parentId);
      } else {
        newExpandedParents.add(parentId);
      }

      return newExpandedParents;
    });
  };

  const updateSelections = (
    newSelectedParents: Set<string>, newSelectedChildren: Set<string>,
  ) => {
    setSelectedParent(newSelectedParents);
    setSelectedChildren(newSelectedChildren);

    onCheck([
      ...newSelectedChildren,
    ]);
  };

  const toggleChildrenSelection = (
    children: TreeNode, event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const newSelectedChildren = new Set(selectedChildren);
    if (newSelectedChildren.has(children.id)) {
      newSelectedChildren.delete(children.id);
    } else {
      newSelectedChildren.add(children.id);
    }

    const parentNode = treeData.find((node) =>
      node.children?.some((child) => child.id === children.id),
    );

    const newSelectedParents = new Set(selectedParent);
    if (parentNode) {
      const childrenNodes = parentNode.children?.map((child) => child.id) || [];
      const allChildrenSelected = childrenNodes
        .every((id) => newSelectedChildren.has(id));

      if (allChildrenSelected) {
        newSelectedParents.add(parentNode.id);
      } else {
        newSelectedParents.delete(parentNode.id);
      }
    }

    updateSelections(newSelectedParents, newSelectedChildren);
  };

  const toggleParentSelection = (
    parentNode: TreeNode, event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const newSelectedParents = new Set(selectedParent);
    const newSelectedChildren = new Set(selectedChildren);
    const childrenNodes = parentNode.children?.map((child) => child.id) || [];

    if (childrenNodes.every((child) => newSelectedChildren.has(child))) {
      for (const child of childrenNodes) {
        newSelectedChildren.delete(child);
      }

      newSelectedParents.delete(parentNode.id);
    } else {
      for (const child of childrenNodes) {
        newSelectedChildren.add(child);
      }

      newSelectedParents.add(parentNode.id);
    }

    updateSelections(newSelectedParents, newSelectedChildren);
  };

  const getSelectedChildrenByParentNode = (parentNode: TreeNode) =>
    selectedData.find((parent) => parent.id === parentNode.id)?.children;

  const isParentSelected = (parentNode: TreeNode) => {
    const childrenNodes = parentNode.children?.map((child) => child.id) || [];
    return childrenNodes.every((child) => selectedChildren.has(child));
  };

  const isSomeUnderParentSelected = (parentNode: TreeNode) => {
    const childrenNodes = parentNode.children?.map((child) => child.id) || [];
    return childrenNodes.some((child) => selectedChildren.has(child));
  };

  const isChildrenSelected = (childId: string) => selectedChildren.has(childId);

  return (
    <div className='scroll-container !max-h-full !p-0'>
      {treeData.map((parentNode) =>
        <div className='mb-[0.5rem]' key={parentNode.id}>
          <div
            className={`${parentStyle} ${expandedParents.has(parentNode.id) && '!rounded-b-none !bg-rgba-black-200'}`}
            onClick={(event) => toggleExpand(parentNode.id, event)}
          >
            <TreeMultiSelectorOption
              dataSelectionValue={parentDataSelectionValue}
              halfSelected={isSomeUnderParentSelected(parentNode)}
              isParent
              key={parentNode.id}
              option={parentNode}
              selected={isParentSelected(parentNode)}
              toggleSelect={toggleParentSelection}
            />
            <div className='flex h-full cursor-pointer items-center gap-[0.75rem]'>
              <div className='flex h-[1.25rem] min-w-fit items-center justify-center rounded-full bg-black-200 px-[0.5rem] py-[0.75rem]'>
                <p className='m-0 text-font-3'>{getSelectedChildrenByParentNode(parentNode)?.length || 0}<span className='text-black-800'>/{parentNode.children?.length} accounts</span></p>
              </div>
              <Svgicon
                className={`w-auto opacity-40 transition-transform duration-200 ${expandedParents.has(parentNode.id) ? 'rotate-180' : 'rotate-0'}`}
                id='arrow-down'
              />
            </div>
          </div>
          {expandedParents.has(parentNode.id) && <div className={childStyle}>
            {parentNode.children?.map((childNode) =>
              <TreeMultiSelectorOption
                dataSelectionValue={childDataSelectionValue}
                isParent={false}
                key={childNode.id}
                option={childNode}
                selected={isChildrenSelected(childNode.id)}
                toggleSelect={toggleChildrenSelection}
              />
              ,
            )}
          </div>}
        </div>,
      )}
    </div>
  );
};
