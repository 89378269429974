import styles from './LoginButton.module.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';

type Props = {
  className?: string,
  disabled?: boolean,
  isLoading?: boolean,
  // for e2e tests, so element can be find easily
  name?: string,
  onClick?: () => void,
  style?: React.CSSProperties,
  text: string,
  type?: 'button' | 'submit',
};

const Spinner = () => <div className={styles.spinner}>
  <div />
  <div />
  <div />
  <div />
</div>;

export const LoginButton = ({
  className = '',
  disabled = false,
  isLoading = false,
  name = '',
  onClick = () => {},
  style,
  text = 'button',
  type = 'button',
}: Props) => <button
  className={classNames(styles.loginButton, className, {'hover:bg-label-g-400 active:bg-label-g-500': !disabled},
  )}
  data-test={name}
  disabled={isLoading || disabled}
  onClick={onClick}
  style={style}
  // eslint-disable-next-line react/button-has-type
  type={type}
>
  {isLoading ? <Spinner /> : text}
</button>;
