export type DoubleTitleTypes = {
  subTitle?: string,
  title: string,
};

export const DoubleTitle = ({
  subTitle,
  title,
}: DoubleTitleTypes) => <>
  <span className='text-title-1' style={{display: 'block'}}>{title}</span>
  {subTitle && <span className='text-title-3 text-black-700' style={{display: 'block'}}>{subTitle}</span>}
</>;
