import { type CapitalAccountsStoreState } from '../../../../../store/slices';
import { type PeriodComputedValuesRecord,
  type TableColumnDisplayValues } from '../../../../../types';
import { formatNumber } from '../../../../Pipes/numberPipes';
import { type FC} from 'react';
import { useSelector } from 'react-redux';

export type CapitalAccountsDataRendererProps = {
  accountId: string,
  fundId: string,
  periods: PeriodComputedValuesRecord,
  valueKey: keyof TableColumnDisplayValues,
};

export const CapitalAccountsDataRenderer: FC<CapitalAccountsDataRendererProps> = ({
  accountId,
  fundId,
  periods,
  valueKey: key,
}) => {
  const currentPeriod = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .selectedFundAccountPeriodMap[fundId][accountId],
  ) as string;

  return <p className='my-0 px-0.25 py-0.5 text-title-5'>{formatNumber(periods[currentPeriod][key] as number, 0)}</p>;
};
