import React, { type FC } from 'react';
import { Navigate,
  Outlet,
  Route,
  type RouteProps,
  Routes } from 'react-router-dom';

export type SubRoute = {
  element: FC,
  hasNesting?: boolean,
  index?: boolean,
  parameter?: string,
  subRouteString: string,
};

type RouteMatcherProps = RouteProps & {
  baseRouteString?: string,
  fallbackElement: FC,
  fallbackLink?: string,
  subRoutes: SubRoute[],
};

/**
 * A way to match relative nested paths to the right components. Originally,
 * the Wrapper component was created as a way to encapsulate the switching of
 * routes to render different components. However, Wrapper only allowed for a
 * single level of nesting. If more levels of nesting was required, it would not work.
 *
 * Instead, this component uses React Router 6 API to build routes based on given input sub-routes
 * as well as support for a base route. This gives Route Matcher a lot of flexibility to build pages
 * with deep nesting and parameters. Route ranking will still be based on the order of the array of sub-routes given.
 *
 * @returns An array of routes
 */
const RouteMatcher: FC<RouteMatcherProps> = ({baseRouteString,
  fallbackElement,
  fallbackLink,
  subRoutes,
  ...rest}) => {
  const { id,
    path } = rest;

  if (subRoutes.length === 0) {
    return null;
  }

  const getFallbackRoute = (ElementComponent: FC, link?: string) => {
    if (link) {
      return <Route element={<Navigate replace to={link} />} path='*' />;
    }

    return <Route element={<ElementComponent />} path='*' />;
  };

  const mapSubRoutes = (routes: SubRoute[]) => routes.map(({
    element: ElementComponent,
    hasNesting = false,
    index = false,
    parameter,
    subRouteString,
  }) => {
    if (index) {
      return <Route
        element={<ElementComponent />} index key={`${path}/${id}/ind ${id}`}
      />;
    }

    if (!hasNesting && !parameter) {
      return (
        <Route
          element={<ElementComponent />}
          key={`${path}/${subRouteString}
          ${id}`} path={subRouteString}
        />
      );
    }

    if (!parameter) {
      // has nesting, no parameter
      return (
        <Route
          element={<ElementComponent />}
          key={`${path}/${subRouteString}/* ${id}`}
          path={`${subRouteString}/*`}
        />);
    }

    if (!hasNesting) {
      // has parameter, no nesting
      return (
        <Route
          element={<ElementComponent />}
          key={`${path}/${subRouteString}/${parameter} ${id}`}
          path={`${subRouteString}/${parameter}`}
        />
      );
    }

    // has parameter and nesting
    // (i.e. parameter has nesting, e.g. ../file/:fileId/*. subRouteString = "file", parameter = ":fileId")
    return (
      <Route
        key={`${path}/${subRouteString}/${parameter} ${id}/*`}
        path={subRouteString}
      >
        <Route
          element={<ElementComponent />}
          path={`${parameter}/*`}
        />
      </Route>
    );
  });

  if (baseRouteString) {
    return (
      <Routes>
        <Route path={baseRouteString}>
          {
            mapSubRoutes(subRoutes)
          }
          {
            getFallbackRoute(fallbackElement, fallbackLink)
          }
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<Outlet />} path=''>
        {
          mapSubRoutes(subRoutes)
        }
        {
          getFallbackRoute(fallbackElement, fallbackLink)
        }
      </Route>
    </Routes>
  );
};

export { RouteMatcher };
