import { Alert } from '../../api/utils';
import { type DocumentsStoreState } from '../../store/slices';
import { getFMSUrl } from './ReportSelectors';
import style from './ReportsModule.module.scss';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { ModalActivationContext } from 'contexts';
import {
  type CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef} from 'react';
import { useSelector } from 'react-redux';

export const ReportsModule = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { togglePerformanceReportsModal } = useContext(ModalActivationContext);

  const {
    reports,
    reportsError,
    reportsPending,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const onClickViewReportsModal = async () => {
    const reportedPeriodsAmount = reports?.length;
    const firstPeriodHasExactlyOneReport = reports && reports[0]?.reports.length === 1;
    if (reportedPeriodsAmount === 1 && firstPeriodHasExactlyOneReport) {
      const newWindow = window.open('', '_blank');
      const url = await getFMSUrl(reports[0].reports[0]._id);
      if (newWindow) {
        newWindow.location.href = url;
      }
    } else {
      togglePerformanceReportsModal(true);
    }
  };

  const containerStyle = useMemo(() => {
    const newContainerStyle: CSSProperties = {};

    if (reportsPending || reports && reports.length === 0) {
      newContainerStyle.display = 'none';
    }

    return newContainerStyle;
  }, [
    reports,
    reportsPending,
  ]);

  useEffect(() => {
    if (reportsError) {
      Alert(reportsError);
    }
  }, [
    reportsError,
  ]);

  return (
    <div
      className={style.reportsModule}
      ref={containerRef}
      style={containerStyle}
    >
      <div className={style.reportsLeft}>
        <h3 className={style.reportsTitle}>
          Performance reports
        </h3>
        {
          reports && <Button
            className='!h-8 w-[5.5rem]'
            data-test='performance-reports-modal-open'
            onClick={onClickViewReportsModal}
            size='medium'
            tooltipWrapperClass='!justify-start'
            type='total-black'
          >
            View
          </Button>
        }
      </div>
    </div>
  );
};
