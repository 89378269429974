import './MultiSelectorOptionList.scss';
import { MultiSelectorOption } from '../MultiSelectorOption/MultiSelectorOption';
import {type SelectDisplayOption,
  type SelectOption } from 'hooks';
import { type FC } from 'react';

type MultiSelectorOptionListProps = {
  dataSelectionValue: string,
  focused: boolean,
  isAllOption: (option: SelectDisplayOption) => boolean,
  onClickOption: (option: SelectDisplayOption) =>
  (event: React.MouseEvent<HTMLDivElement>) => void,
  optionList: SelectOption[],
  options: SelectDisplayOption[],
};

const MultiSelectorOptionList: FC<MultiSelectorOptionListProps> = ({dataSelectionValue,
  focused,
  isAllOption,
  onClickOption,
  optionList,
  options}) => {
  return <div className={`!absolute block ${focused && 'blockActive'}`}>
    <div className='optionContainer' style={{ height: optionList.length ? '' : 'max-content' }}>
      {
        focused && options.map((option) => <MultiSelectorOption
          dataSelectionValue={dataSelectionValue}
          isAllOption={isAllOption(option)}
          key={option.id}
          onClickOption={onClickOption}
          option={option}
        />)
      }
    </div>
  </div>;
};

export default MultiSelectorOptionList;
