import { type RefObject,
  useEffect,
  useState } from 'react';

type Dimensions = {
  height: number,
  width: number,
};

const INITIAL_DIMENSIONS: Dimensions = {
  height: 0,
  width: 0,
};

/**
 * Problem: it is difficult to keep track of an element's size without adding event listeners and additional state
 * to track it's dimensions.
 *
 * This hook solves the problem by storing and adjusting the width/height of given element on resize.
 *
 * @param ref The Ref Object to track the dimensions of.
 * @returns The width and height of the element.
 */

const useResize = <T extends HTMLElement | SVGSVGElement>(ref: RefObject<T>) => {
  const [
    dimensions,
    setDimensions,
  ] = useState<Dimensions>(INITIAL_DIMENSIONS);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      if (ref.current) {
        const { height,
          width } = ref.current.getBoundingClientRect();
        setDimensions({ height,
          width });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [
    ref,
  ]);

  return dimensions;
};

const getInitialWindowDimensions = () => ({ height: window.innerHeight,
  width: window.innerWidth } as Dimensions);

/**
 * Tracks the size of the window opened.
 *
 * @returns The width and height of the window.
 */
const useWindowResize = () => {
  const [
    dimensions,
    setDimensions,
  ] = useState<Dimensions>(getInitialWindowDimensions());

  useEffect(() => {
    const getDimensions = () => ({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
};

export { useResize,
  useWindowResize };
