import {firmsActionTypes} from '../actions';

const initialState = {
  firmsArray: [],
};

// eslint-disable-next-line default-param-last
export const firms = (state = initialState, {payload,
  type}) => {
  switch (type) {
  case firmsActionTypes.GET_FIRMS_SUCCESS:
    return {...state,
      firmsArray: payload};
  case firmsActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
