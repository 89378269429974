import {authActionTypes} from '../actions';
import {getItemsFromStorage} from 'types';

const {
  access_token: accessToken,
  email,
  exp,
  fullPhoneNumber: phone,
  refresh_token: refreshToken,
  verificationChannel,
} = getItemsFromStorage([
  'access_token',
  'refresh_token',
  'verificationChannel',
  'email',
  'exp',
  'fullPhoneNumber',
], sessionStorage);

const initialState = {
  access_token: accessToken || null,
  accessToken: accessToken || null,
  changePassSendLinkSuccess: false,
  email,
  exp,
  justLoggedIn: false,
  justLoggedOut: false,
  passwordSuccessfullyChanged: false,
  phone,
  refresh_token: refreshToken || null,
  refreshToken: refreshToken || null,
  verificationChannel,
};

// eslint-disable-next-line default-param-last
export const auth = (state = initialState, {payload,
  type}) => {
  switch (type) {
  case authActionTypes.SET_LOGIN_SUCCESS:
    return {...state,
      ...payload};
  case authActionTypes.SET_ACCESS_TOKEN:
    return {...state,
      access_token: payload.access_token,
      accessToken: payload.access_token,
    };
  case authActionTypes.SET_REFRESH_TOKEN:
    return {...state,
      refresh_token: payload.refresh_token,
      refreshToken: payload.refresh_token,
    };
  case authActionTypes.SET_JUST_LOGGED_IN:
    return {...state,
      justLoggedIn: payload.justLoggedIn};
  case authActionTypes.SET_JUST_LOGGED_OUT:
    return {...state,
      justLoggedIn: payload.justLoggedOut};
  case authActionTypes.SET_RESET_PASSWORD_SUCCESS:
    return {...state,
      changePassSendLinkSuccess: payload};
  case authActionTypes.CHANGE_PHONE_NUMBER:
    return {...state,
      phone: payload.replace('+', '')};
  case authActionTypes.CHANGE_EMAIL:
    return {...state,
      email: payload};
  case authActionTypes.PASSWORD_SUCCESSFULLY_CHANGED:
    return {...state,
      passwordSuccessfullyChanged: payload};
  case authActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
