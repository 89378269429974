import { type CapitalAccountFund } from '../types';
import { Api } from './Api';
import { Alert,
  unauthorized } from './utils';
import { isAxiosError } from 'axios';

export type CapitalAccountsFilter = {
  accountIds: string[],
  firmId: string,
};

const capitalAccountsRequester = new Api('/api/v1/firms');

const checkForCapitalAccounts = async ({ accountIds,
  firmId }: CapitalAccountsFilter) => {
  try {
    const {data} = await capitalAccountsRequester
      .post<{accountIds: string[], firmId: string,},
    { hasAnyCAReports: boolean, }>('/capital-accounts/preview',
      {
        accountIds,
        firmId,
      },
      {});
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve capital account information');
    }

    throw new Error('Could not retrieve capital account information.');
  }
};

const getCapitalAccounts = async ({ accountIds,
  firmId }: CapitalAccountsFilter) => {
  try {
    const {data} = await capitalAccountsRequester
      .post<{accountIds: string[], firmId: string,},
    {data: CapitalAccountFund[],}>('/capital-accounts',
      {
        accountIds,
        firmId,
      },
      {});
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve capital account information');
    }

    throw new Error('Could not retrieve capital account information.');
  }
};

export const capitalAccountsApi = {
  checkForCapitalAccounts,
  getCapitalAccounts,
};
