import LoginImage from '../../assets/images/login_image.png';
import loginHeaderStyle from '../../components/LoginHeader/LoginHeader.module.scss';
import {
  FiltersContext,
  SignUpActivationContext,
  SignUpActivationProvider,
} from '../../contexts';
import style from './Authorization.module.scss';
import { ROUTE_ARRAY } from './routes';
import { organizationsApi } from 'api/organizationsApi';
import userPublicApi from 'api/user-public';
import {
  LoginContactSupport,
  LoginHeader,
  PrivacyAndTerms,
  RouteMatcher,
  SixCardsSvg,
  SkipEnhancedSecurityModal,
} from 'components';
import { Error } from 'pages/Error';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';

const AuthorizationWithoutContext = () => {
  const { isSignUp,
    showSecurityModal } = useContext(SignUpActivationContext);

  const { pathname,
    search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const { dataToken,
    token } = Object.fromEntries(queryParameters.entries());
  const navigate = useNavigate();
  const { setFirmId } = useContext(FiltersContext);

  const [
    orgLogo,
    setOrgLogo,
  ] = useState('');

  useEffect(() => {
    const getOrgLogo = async () => {
      if (dataToken) {
        const res = await organizationsApi.getOrgData(dataToken);
        const data = res?.data;
        if (data) {
          setOrgLogo(data.logo);
          setFirmId(data.firmId);
        }
      } else {
        setFirmId('');
      }

      if (token && pathname.includes('/signup')) {
        const { isActive } = await userPublicApi.getUserIsActive(token);
        if (isActive) {
          navigate({
            pathname: 'login',
            search: createSearchParams({
              dataToken,
            }).toString(),
          });
        }
      }
    };

    getOrgLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataToken,
  ]);

  return (
    <div className={style.loginContainer}>
      <LoginHeader />
      <main className={style['login-content-wrapper']}>
        <div className={style['form-wrapper']}>
          {orgLogo && <div className='flex h-full items-center justify-center dt:items-end dt:justify-start dt:pb-1'>
            <div className='flex h-5.5 items-center justify-center dt:justify-start' data-test='firm-logo'>
              <img alt='orgLogo' className='max-hw-full max-h-full' src={orgLogo} />
            </div>
          </div>}
          <div className='flex flex-col gap-1'>
            <RouteMatcher fallbackElement={Error} subRoutes={ROUTE_ARRAY} />
            {!isSignUp && <LoginContactSupport />}
          </div>
          {orgLogo && <div className='h-full' />}
        </div>
        <div className={style['picture-wrapper']}>
          <img alt='documents' src={LoginImage} />
        </div>
      </main>
      <div>
        <div
          className={`${loginHeaderStyle['other-software-wrapper']} ${loginHeaderStyle.mobile}`}
        >
          <div className='flex flex-col gap-1'>
            <div className={loginHeaderStyle['grey-line']} />
            <button
              className={`${loginHeaderStyle.otherSolutions} 
              ${loginHeaderStyle.mobile}`}
              onClick={() => {
                window.location.href = 'https://www.servc.co.il/log-in-all-softwares';
              }}
              type='button'
            >
              <SixCardsSvg />
              Other software solutions
            </button>
          </div>
        </div>
        {showSecurityModal && <SkipEnhancedSecurityModal />}
        <PrivacyAndTerms />
      </div>
    </div>
  );
};

export const Authorization = () => {
  return (
    <SignUpActivationProvider>
      <AuthorizationWithoutContext />
    </SignUpActivationProvider>
  );
};
