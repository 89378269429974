import {globalActionTypes} from '../actions';

const initialState = {
  loading: false,
};

// eslint-disable-next-line default-param-last
export const global = (state = initialState, {payload,
  type}) => {
  switch (type) {
  case globalActionTypes.LOADING:
    return {...state,
      loading: payload};
  default:
    return state;
  }
};
