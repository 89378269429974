import { type AggregatedCustomColumns,
  type CapitalAccountAccount,
  type CapitalAccountFund,
  type CapitalAccountPeriodInformation,
  type CapitalAccountPeriodRecord,
  type ColumnsToDisplayEnum,
  type ComputedCapitalAccountFund,
  type ComputedCapitalAccountInfoAccount,
  type FixedColumns,
  type NavColumn,
  type PeriodComputedValuesRecord,
  SecondTableColumnKeys,
  type SelectedFundAccountPeriodMap,
  type TableColumnDisplayValues } from 'types';

const getInitialFixedColumns = () => {
  return {
    commitment: 0,
    contribution: 0,
    distribution: 0,
    realizedGainLoss: 0,
    unrealizedGainLoss: 0,
  } as FixedColumns;
};

const getInitialAggregatedCustomColumn = () => {
  return {
    other: 0,
  } as AggregatedCustomColumns;
};

export const getInitialTableColumnDisplayValues = (legalName: string) => ({
  ...getInitialFixedColumns(),
  ...getInitialAggregatedCustomColumn(),
  legalName,
  nav: 0,
});

export const isOnlyOneFundAndInvestor = (data: CapitalAccountFund[]) => {
  return data?.length === 1 && data[0]?.accounts?.length === 1;
};

const accumulateCustomColumnsForPeriod = (
  accumulator: number,
  {
    capitalAccountReport,
    data,
  }: CapitalAccountPeriodInformation,
) => {
  // Collect the custom column keys from capitalAccountReport
  const { customColumns } = capitalAccountReport;
  const customColumnKeys = customColumns.map((customColumn) => customColumn.key);

  // For each custom column key, sum up and add
  const calculatedOtherTotalInPeriod = customColumnKeys
    .reduce((totalAcc, key) => totalAcc + (data[key] || 0), 0);
  return accumulator + calculatedOtherTotalInPeriod;
};

const calculateNavColumn = (
  mandatoryColumns: FixedColumns,
  otherColumn: AggregatedCustomColumns,
): NavColumn => {
  const mandatoryTotal = Object
    .keys(mandatoryColumns)
    .filter((key) => SecondTableColumnKeys.includes(key as ColumnsToDisplayEnum))
    .reduce(
      (accTotal, curr) => accTotal + mandatoryColumns[curr as keyof FixedColumns],
      0,
    );

  return {
    nav: mandatoryTotal + otherColumn.other,
  };
};

const convertPeriods = (
  legalName: string,
  periods: CapitalAccountPeriodRecord,
): PeriodComputedValuesRecord => {
  const result: PeriodComputedValuesRecord = {};
  for (const period of Object.keys(periods)) {
    const info = periods[period];

    const mandatoryColumns = getInitialFixedColumns();
    for (const key of Object.keys(mandatoryColumns) as
    Array<keyof FixedColumns>) {
      mandatoryColumns[key] = info.data[key] || 0;
    }

    const otherColumn: AggregatedCustomColumns = {
      other: accumulateCustomColumnsForPeriod(0, info),
    };

    const navColumn = calculateNavColumn(mandatoryColumns, otherColumn);

    result[period] = {
      ...mandatoryColumns,
      ...otherColumn,
      ...navColumn,
      legalName,
    };
  }

  return result;
};

const calculateColumnsForAccount = (
  {
    _id,
    legalName,
    periods,
  }: CapitalAccountAccount,
): ComputedCapitalAccountInfoAccount => {
  return {
    _id,
    legalName,
    periods: convertPeriods(legalName, periods),
  };
};

export const calculateColumnsForFund = (
  {
    _id,
    accounts,
    entityNumber,
    legalName,
  }: CapitalAccountFund,
): ComputedCapitalAccountFund => {
  return {
    _id,
    accounts: accounts.map(calculateColumnsForAccount),
    entityNumber,
    legalName,
  };
};

const mergeTableColumnDisplayValues = (
  toAdd: TableColumnDisplayValues,
  base: TableColumnDisplayValues,
): TableColumnDisplayValues => {
  // Create a shallow copy of the base object
  const result: TableColumnDisplayValues = { ...base };

  // Iterate over the keys of toAdd
  for (const key of Object.keys(toAdd) as Array<keyof TableColumnDisplayValues>) {
    if (key !== 'legalName') {
      // Add the values, considering undefined as 0
      result[key] = (result[key] || 0) + (toAdd[key] || 0);
    }
  }

  return result;
};

export const calcSubtotalRow = (
  computedAccounts: ComputedCapitalAccountInfoAccount[],
  selectedAccountPeriodMap: Record<string, string>,
  fundName?: string,
): TableColumnDisplayValues => {
  return computedAccounts
    .reduce<TableColumnDisplayValues>((prev, {
    _id: accountId,
    periods,
  }) => {
    const currentPeriod = selectedAccountPeriodMap[accountId];
    const periodTotal = periods[currentPeriod];
    return mergeTableColumnDisplayValues(periodTotal, prev);
  }, getInitialTableColumnDisplayValues(fundName ? fundName : 'Subtotal'));
};

export const calcSummaryRow = (
  computedFunds: ComputedCapitalAccountFund[],
  selectedFundAccountPeriodMap: SelectedFundAccountPeriodMap,
): TableColumnDisplayValues => {
  return computedFunds
    .reduce<TableColumnDisplayValues>((prev, {
    _id: fundId,
    accounts,
  }) => {
    const selectedAccountPeriodMap = selectedFundAccountPeriodMap[fundId];
    const subtotal = calcSubtotalRow(accounts, selectedAccountPeriodMap);
    return mergeTableColumnDisplayValues(subtotal, prev);
  }, getInitialTableColumnDisplayValues('Total'));
};
