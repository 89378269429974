import { RecentDocument } from './components/RecentDocument';
import { UsualDocument } from './components/UsualDocument';
import { documentsApi } from 'api/documentsApi';
import { actionCreator } from 'api/utils';
import { ModalActivationContext } from 'contexts/ModalActivation.context';
import {
  type FilesStore,
  type IndividualFileStore,
} from 'pages/Firm/FileViewModal/FileViewModal';
import {
  type FC,
  type SyntheticEvent,
  useContext,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { notificationsService } from 'services';
import { filesActionTypes } from 'store/actions';
import { type DocumentsStoreState,
  markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDetailsFromStorage,
  type DocumentDTO,
  getItemsFromStorage,
  type LoginAsFromStorage,
  storeItemsInStorage,
} from 'types';

type DocumentComponentProps = {
  categoryId: string,
  documentObject: DocumentDTO,
};

export const Document: FC<DocumentComponentProps> = ({
  categoryId,
  documentObject,
}) => {
  const dispatch = useDispatch();
  const { toggleFileViewModal } = useContext(ModalActivationContext);

  const {
    fileByIdLoading,
    viewingFileByIdLoading,
  } = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;

  const {loginAs} = getItemsFromStorage<LoginAsFromStorage>([
    'loginAs',
  ], sessionStorage);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _id,
    fileName,
    fileType,
    quarter,
    userViewed,
    year } = documentObject;

  const groupName = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents.newCategory
      ?.documentTypeGroups.find((group) => group.documentGroupId === fileType)
      ?.documentGroupName,
  ) as string;

  const downloadClick = async (event: SyntheticEvent | undefined) => {
    event?.stopPropagation();
    dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, true));
    try {
      await documentsApi.downloadDocument(fileName as string, _id as string);
      if (!userViewed && !loginAs) {
        // backend will take care of "viewed" when file is "downloaded"
        // this is just for optimistic ui update
        dispatch(markDocumentsAsSeen({
          categoryId,
          documentGroupId: fileType,
          documentIds: [
            _id,
          ],
        }));
      }
    } catch (error) {
      notificationsService.error(`Error while downloading the file: ${error}`);
    }

    dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
  };

  const toggleDocumentViewedStatus = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    try {
      dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, true));
      await documentsApi.viewDocuments({
        documentIds: [
          _id,
        ],
      });

      dispatch(markDocumentsAsSeen({
        categoryId,
        documentGroupId: fileType,
        documentIds: [
          _id,
        ],
      }));
      dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
    } catch {
      notificationsService.error('Error while viewing');
      dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
    }
  };

  const parts = fileName.split('_');
  const period = quarter && year ? `${quarter} ${year}` : year || parts[parts.length - 1] || '';

  const documentOnClick = async (
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    if (event instanceof KeyboardEvent && event.key !== 'Tab') {
      return;
    }

    storeItemsInStorage<DocumentDetailsFromStorage>({
      categoryId,
      documentGroupId: fileType,
      documentGroupName: groupName,
      documentId: _id,
      isNewDocument: userViewed ? 'false' : 'true',
    }, sessionStorage);

    toggleFileViewModal(true, {
      fileName: `${period} ${groupName}`,
      id: _id,
    });
  };

  return (
    <>
      {categoryId === 'New Documents Category' ?
        <RecentDocument
          documentOnClick={documentOnClick}
          downloadClick={downloadClick}
          fileByIdLoading={fileByIdLoading}
          groupName={groupName}
          period={period}
          {
            ...documentObject
          }
        />
        :
        <UsualDocument
          documentOnClick={documentOnClick}
          downloadClick={downloadClick}
          fileByIdLoading={fileByIdLoading}
          period={period}
          toggleDocumentViewedStatus={toggleDocumentViewedStatus}
          viewingFileByIdLoading={viewingFileByIdLoading}
          {
            ...documentObject
          }
        />}
    </>

  );
};
