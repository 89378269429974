import {filesActionTypes} from 'store/actions';

const initialState = {
  downloadFilesLoading: false,
  fileByFileTypeLoading: false,
  fileByIdLoading: false,
  fileLoading: false,
  files: [],
  filesByFileType: {
    files: [],
    fileTypeName: null,
  },
  viewingFileByIdLoading: false,
};

// eslint-disable-next-line default-param-last
export const files = (state = initialState, {payload,
  type}) => {
  switch (type) {
  case filesActionTypes.GET_FIRM_FILES_SUCCESS:
    return {...state,
      files: payload};
  case filesActionTypes.GET_FIRM_FILES_BY_FILETYPE_SUCCESS:
    return {...state,
      filesByFileType: payload};
  case filesActionTypes.FILE_LOADING:
    return {...state,
      fileLoading: payload};
  case filesActionTypes.VIEWING_FILE_BY_ID_LOADING:
    return {...state,
      viewingFileByIdLoading: payload};
  case filesActionTypes.FILE_BY_FILETYPE_LOADING:
    return {...state,
      fileByFileTypeLoading: payload};
  case filesActionTypes.FILE_BY_ID_LOADING:
    return {...state,
      fileByIdLoading: payload};
  case filesActionTypes.DOWNLOAD_FILES_LOADING:
    return {...state,
      downloadFilesLoading: payload};
  case filesActionTypes.CLEAR_FILES:
    return {
      ...state,
      files: [],
      filesByFileType: {
        files: [],
        fileTypeName: null,
      },
    };
  case filesActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
