import { MultiSelector } from '../../MultiSelector';
import { type SelectorProps } from '../YearSelector';
import { type FC,
  useEffect,
  useState} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { filterDocumentsDataByTypes,
  type OrganizationsStoreState } from 'store/slices';
import { type NestedOrganization } from 'types';

/**
 * Renders a multiselector component for document types
 */
const getAllTypes = (data: NestedOrganization | null) => {
  const types = data?.funds.flatMap((fund) => fund.accounts)
    .flatMap((acc) => acc.permissions) ?? [];

  const uniqueTypes = new Map(types.map((type) => [
    type.code,
    type,
  ]));

  return Array.from(uniqueTypes.values());
};

const getAvailableTypes = (data: NestedOrganization | null, accountIds: string[]) => {
  const types = data?.funds.flatMap((fund) => fund.accounts)
    .flatMap((acc) => accountIds.includes(acc.accountId) ? acc.permissions : []) ?? [];

  const uniqueTypes = new Map(types.map((type) => [
    type.code,
    type,
  ]));

  return Array.from(uniqueTypes.values());
};

const getOptions = (data: NestedOrganization | null, accountIds?: string[]) => {
  const types = accountIds ? getAvailableTypes(data, accountIds) : getAllTypes(data);
  const typeOption = types.map((type) => {
    return {display: type.name,
      id: type.code,
    };
  });

  return typeOption;
};

const getAccountsIds = (data: NestedOrganization | null) => {
  return data?.funds.flatMap((fund) => fund.accounts.map((acc) => acc.accountId)) ?? [];
};

const TypeSelector: FC<SelectorProps> = ({activeDocumentGroupName,
  style}: SelectorProps) => {
  const dispatch = useDispatch();
  const {
    displayedOrganizationWithPermissions,
    selectedDocumentsData} = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const [
    typeOptions,
    setTypeOptions,
  ] = useState(getOptions(displayedOrganizationWithPermissions as NestedOrganization));

  const [
    selectedTypeIds,
    setSelectedTypeIds,
  ] = useState(activeDocumentGroupName ?
    [
      activeDocumentGroupName,
    ] as string[]
    : typeOptions.map((opt) => opt.id));

  const [
    selectedAccIds,
    setSelectedAccIds,
  ] = useState(
    getAccountsIds(displayedOrganizationWithPermissions as NestedOrganization));

  useEffect(() => {
    if (selectedDocumentsData) {
      const newAccIds = getAccountsIds(selectedDocumentsData);
      if (newAccIds.length !== selectedAccIds.length) {
        setSelectedAccIds(newAccIds);
        const newOptions =
         getOptions(
           displayedOrganizationWithPermissions as NestedOrganization, newAccIds);
        setTypeOptions(newOptions);
        if (activeDocumentGroupName) {
          const activeOption = newOptions
            .find((opt) => opt.id === activeDocumentGroupName);
          setSelectedTypeIds([
            activeOption?.id as string,
          ]);
        } else {
          setSelectedTypeIds(newOptions.map((opt) => opt.id));
        }
      }
    }
  }, [
    activeDocumentGroupName,
    selectedDocumentsData,
  ]);

  const checkType = (typeIds: string[]) => {
    dispatch(filterDocumentsDataByTypes(typeIds));
    setSelectedTypeIds(typeIds);
  };

  return (
    <MultiSelector
      dataSelectionValue='download-files-select-filters-filetype-multiselector'
      disabled={!typeOptions.length}
      onOption={checkType}
      optionList={typeOptions}
      placeholder='file type'
      selectedIds={selectedTypeIds}
      style={style || { width: '250px' }}
      title='Document type'
    />
  );
};

export { TypeSelector };
