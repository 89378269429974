import 'pdfjs-dist/web/pdf_viewer.css';
import { Spinner } from 'components/Spinner';
import * as pdfjsLib from 'pdfjs-dist';
// eslint-disable-next-line import/extensions
import PDFJSWorkerUrl from 'pdfjs-dist/build/pdf.worker?url';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { isLandscape } from 'services/mobile';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorkerUrl;

type PdfJsReaderProps = {
  url: string,
};

// Define Row outside of PdfJsReader to prevent re-creation on each render
type RowProps = {
  index: number,
  renderPage:
  (pageNum: number, canvasRef: React.RefObject<HTMLCanvasElement>) => Promise<void>,
  style: React.CSSProperties,
};

const PAGE_GAP = 20;

const Row: React.FC<RowProps> = ({
  index,
  renderPage,
  style,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    renderPage(index + 1, canvasRef);
  }, [
    index,
    renderPage,
  ]);

  return (
    <div
      style={{
        ...style,
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'hidden',
        paddingBottom: PAGE_GAP / 2,
        paddingTop: PAGE_GAP / 2,
      }}
    >
      <canvas ref={canvasRef} style={{ backgroundColor: 'white' }} />
    </div>
  );
};

export const PdfJsReader: React.FC<PdfJsReaderProps> = ({ url }) => {
  const [
    pdf,
    setPdf,
  ] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
  const [
    itemSize,
    setItemSize,
  ] = useState<number>(0);
  const listRef = useRef<List>(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument({
          url,
          withCredentials: true,
        });
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [
    url,
  ]);

  useEffect(() => {
    const calculateItemSize = async () => {
      if (!pdf) {
        return;
      }

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.5 });
      const displayScale = isLandscape() ? 1.5 : 0.75;
      const height = viewport.height * (displayScale / 1.5);
      // Add gap to the item size
      setItemSize(height + PAGE_GAP);
    };

    calculateItemSize();
  }, [
    pdf,
  ]);

  const renderPage = useCallback(
    async (pageNum: number, canvasRef: React.RefObject<HTMLCanvasElement>) => {
      if (!pdf || !canvasRef.current) {
        return;
      }

      const pixelRatio = window.devicePixelRatio || 1;
      const renderScale = 1.5;
      const displayScale = isLandscape() ? 1.5 : 0.75;

      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: renderScale });

      const canvas = canvasRef.current;
      canvas.width = viewport.width * pixelRatio;
      canvas.height = viewport.height * pixelRatio;
      canvas.style.width = `${viewport.width * (displayScale / renderScale)}px`;
      canvas.style.height = `${viewport.height * (displayScale / renderScale)}px`;

      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }

      context.scale(pixelRatio, pixelRatio);

      const renderContext = {
        canvasContext: context,
        viewport,
      };

      await page.render(renderContext).promise;
    },
    [
      pdf,
    ],
  );

  return (
    <div
      style={{
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
      }}
    >
      {pdf && itemSize ?
        <AutoSizer>
          {({
            height,
            width,
          }: {
            // eslint-disable-next-line react/no-unused-prop-types
            height: number,
            // eslint-disable-next-line react/no-unused-prop-types
            width: number,
          }) =>
            <List
              height={height}
              itemCount={pdf.numPages}
              itemSize={itemSize}
              ref={listRef}
              width={width}
            >
              {({
                index,
                style,
              }: {
                // eslint-disable-next-line react/no-unused-prop-types
                index: number,
                // eslint-disable-next-line react/no-unused-prop-types
                style: React.CSSProperties,

              }) =>
                <Row
                  index={index}
                  renderPage={renderPage}
                  style={style}
                />
              }
            </List>
          }
        </AutoSizer>
        :
        <Spinner />
      }
    </div>
  );
};
