/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import style from './blocks.module.scss';
import {RotatingLines} from 'react-loader-spinner';

export const Blocks = ({Icon,
  children,
  inputStyle = {},
  loading,
  onClick,
  title}) => {
  const getResult = () => {
    if (loading) {
      return (
        <div className={style.titleWithIcon}>
          <RotatingLines
            animationDuration='0.75'
            strokeColor='grey'
            strokeWidth='5'
            visible
            width='30'
          />
          <div className={style.title}>
            {title}
          </div>
        </div>
      );
    }

    if (Icon) {
      return (
        <div className={style.titleWithIcon}>
          {/* eslint-disable-next-line react/forbid-component-props */}
          <Icon style={{height: '30px',
            width: '30px'}}
          />
          <div className={style.title}>
            {title}
          </div>
        </div>
      );
    }

    return (
      <div className={style.title}>
        {title}
      </div>
    );
  };

  return <div
    className={style.container}
    onClick={onClick}
    style={inputStyle}
  >
    {
      getResult()
    }
    {children ? children : ''}
  </div>;
};
