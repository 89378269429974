/* eslint-disable max-len */
/* eslint-disable react/forbid-component-props */
// eslint-disable-next-line import/no-unassigned-import
import './antd.scss';
import userApi from '../../api/user';
import { Dropdown } from '../Dropdown';
import { FileUploader } from '../FileUploader';
import style from './support.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import {
  INITIAL_SELECT_OPTION_STATE,
  isOptionEmpty,
  type SelectOption} from 'hooks';
import { type ChangeEventHandler,
  type ClipboardEventHandler,
  type FC,
  useState } from 'react';
import { useSelector } from 'react-redux';
import { type OrganizationsStoreState } from 'store/slices';

const SUBJECT_FIELDS = [
  'Change Password',
  'Change Number',
  'Missing Documents',
  'Missing funds',
  'Missing accounts',
];

export const SupportForm: FC<{
  setSupportLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSupportModal: (on: boolean) => void,
  setSupportSuccess: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({setSupportLoading,
  setSupportModal,
  setSupportSuccess}) => {
  const {
    displayedOrganization,
    organizations } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const currentOrganization = organizations
    ?.find((org) => org.organizationId === displayedOrganization?.organizationId);

  const [
    subject,
    setSubject,
  ] = useState(INITIAL_SELECT_OPTION_STATE);
  const [
    fund,
    setFund,
  ] = useState(INITIAL_SELECT_OPTION_STATE);
  const [
    description,
    setDescription,
  ] = useState('');
  const [
    attachments,
    setAttachments,
  ] = useState([] as File[]);

  const onFilesUpdate = (newFiles: File[]) => {
    setAttachments(newFiles);
  };

  // eslint-disable-next-line consistent-return
  const handleOnSubmitClick = () => {
    if (isOptionEmpty(subject) || !description) {
      return;
    }

    const formData = new FormData();
    formData.append('subject', subject.display);
    formData.append('fund', fund.display);
    formData.append('description', description);
    for (const attachment of attachments) {
      formData.append('attachments', attachment);
    }

    userApi.support(formData, setSupportSuccess, setSupportLoading, setSupportModal);
  };

  const handleOnTextAreaChange: ChangeEventHandler<
  HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDescription(event.target.value);
  };

  const handleOnPaste: ClipboardEventHandler<HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDescription(event.currentTarget.value);
  };

  return (
    <div className='flex flex-col gap-1 p-1.25'>
      <div className='flex flex-col gap-0.5'>
        <span className='text-center text-title-3 font-medium'>Get support</span>
        <span className='text-center text-font-2 text-black-700'>We are here to help answer your questions.</span>
      </div>
      <div className={style.error} />
      <Dropdown
        allowOthersOption
        dataSelectionValue='select-subject'
        optionList={SUBJECT_FIELDS.map((sub) => ({ display: sub,
          id: sub }))}
        placeholder='Select Subject'
        setValue={setSubject}
        title='Select Subject'
        value={subject}
      />
      {
        Boolean(currentOrganization?.funds) &&
          <Dropdown
            dataSelectionValue='select-fund'
            // eslint-disable-next-line @typescript-eslint/no-shadow
            optionList={currentOrganization?.funds.map((fund) => ({ display: fund.fundName,
              id: fund.fundId })) as SelectOption[]}
            placeholder='Fund'
            setValue={setFund}
            title='Select fund'
            value={fund}
          />
      }
      <div className='flex flex-col gap-0.125'>
        <div className='text-font-3 font-medium'>
          Description
        </div>
        <TextArea
          className='!rounded-main'
          data-test='description'
          name='description'
          onChange={handleOnTextAreaChange}
          onPaste={handleOnPaste}
          placeholder='Enter Description'
          rows={4}
        />
      </div>
      <FileUploader data-test='file-upload' onFilesUpdate={onFilesUpdate}>
        <div className='text-font-3 text-black-700'>
          You can upload up to 2mb files. jpg, pdf, png supported. If you want to contact us directly, or have more specific issue feel free to send us a message to
          <a className='font-bold text-main-700' href='mailto:helpdesk@servc.co.il'> helpdesk@servc.co.il</a>
        </div>
      </FileUploader>
      <Button
        className='w-full'
        dataTest='submit-request'
        disabled={isOptionEmpty(subject) || !description}
        onClick={handleOnSubmitClick}
        size='large'
        squared
      >
        Send
      </Button>
    </div>
  );
};
