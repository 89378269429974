import {supportActionTypes} from '../actions';

const initialState = {
  support_success: false,
};

// eslint-disable-next-line default-param-last
export const support = (state = initialState, {payload,
  type}) => {
  switch (type) {
  case supportActionTypes.SUPPORT_SUCCESS:
    return {...state,
      support_success: payload};
  case supportActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
