/* eslint-disable canonical/filename-match-exported */
/* eslint-disable canonical/filename-match-regex */
import { notificationsService } from '../services';
import { authActionTypes,
  firmActionTypes,
  globalActionTypes,
  rootActionTypes} from '../store/actions';
import { Api } from './Api';
import { type SuccessResponse } from './common.response';
import userApi from './user';
import {
  actionCreator,
  Alert,
  type AsyncThunkAction,
  processAuthorization,
  unauthorized} from './utils';
import { type Dispatch } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { type NavigateFunction } from 'react-router-dom';
import {
  type AuthorizeVerificationType,
  type LoginAsFromStorage,
  type PrivacyPolicyFromStorage,
  storeItemsInStorage,
  type VerificationChannel} from 'types';

const api = new Api('/api/auth');

type ResendVerificationRequest = {
  channel: VerificationChannel,
};
const resendVerification = (body: ResendVerificationRequest) => api.post<
ResendVerificationRequest, SuccessResponse>('/login/verification/resend', body, {});

type ForgotPasswordRequest = {
  email: string,
};
const forgotPassword = (body: ForgotPasswordRequest) => api.post<
ForgotPasswordRequest, SuccessResponse>('/password-reset', body, {});

type LoginRequestBody = {
  email: string,
  password: string,
};
const login = (
  payload: LoginRequestBody,
  navigate: NavigateFunction,
  verificationRoute: string,
  noVerificationRoute: string,
  afterLoginCallback?: () => void,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  {payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error)) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    const { data } = await api.post<LoginRequestBody, AuthorizeVerificationType>('/login', payload, {});

    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate,
      verificationRoute,
      noVerificationRoute,
      afterLoginCallback,
    );
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 403) {
        navigate('/auth/migration');
        return;
      }

      const message = error.response?.data?.message || error.message as string;

      notificationsService.error(message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

type ChangePhoneNumberBody = {
  loginAs: boolean | string,
  phoneNumber: string,
  refreshToken: string | null,
};
const changePhoneNumber = (
  payload: ChangePhoneNumberBody,
  navigate: NavigateFunction,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  {payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error)) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    const {data} = await api.post<
    ChangePhoneNumberBody, AuthorizeVerificationType
    >('/change-phone', payload, {});
    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate,
      '/auth/verification',
      '/firm/settings',
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

const setAccessToken = (
  access_token: string,
  setInStorage: boolean,
) => (
  dispatch: Dispatch<{payload: unknown, type: string, }>,
) => dispatch(actionCreator(authActionTypes.SET_ACCESS_TOKEN, {
  access_token,
  setInStorage,
}));

const setRefreshToken = (
  refresh_token: string,
  setInStorage: boolean,
) => (
  dispatch: Dispatch<{payload: unknown, type: string, }>,
) => dispatch(actionCreator(authActionTypes.SET_REFRESH_TOKEN, {
  refresh_token,
  setInStorage,
}));

type VerificationRequestBody = {
  code: string,
};
type VerificationRequestResponse = {
  access_token?: string,
  refresh_token?: string,
};
const verification = (
  payload: VerificationRequestBody,
  navigate: NavigateFunction,
  nextPage: string,
  afterVerificationCallback?: () => void,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  {payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  let verificationRequestResponse: VerificationRequestResponse;

  try {
    const {data} = await api.post<
    VerificationRequestBody, VerificationRequestResponse>(
      '/login/verification',
      payload,
      {},
    );

    verificationRequestResponse = data;
  } catch {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
    notificationsService.error('Code is incorrect.');
    return;
  }

  const {
    access_token: accessToken,
    refresh_token: refreshToken,
  } = verificationRequestResponse;

  if (accessToken) {
    dispatchAsThunkDispatch(setAccessToken(accessToken, true));
  }

  if (refreshToken) {
    dispatchAsThunkDispatch(setRefreshToken(refreshToken, true));
  }

  dispatchAsActionDispatch(actionCreator(authActionTypes.SET_LOGIN_SUCCESS
    , verificationRequestResponse));

  try {
    await dispatchAsThunkDispatch(userApi.me());
  } catch (error) {
    if (isAxiosError(error)) {
      navigate('/auth/login');
      Alert(error?.response?.data?.message);
      Alert('Unable to authorize identity. Please try to login again.');
    } else {
      notificationsService.error('Code is incorrect.');
    }

    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));

    return;
  }

  if (afterVerificationCallback) {
    afterVerificationCallback();
  }

  dispatchAsActionDispatch(
    actionCreator(
      authActionTypes.SET_JUST_LOGGED_IN, { justLoggedIn: true},
    ),
  );

  navigate(nextPage);

  setTimeout(() => {
    dispatchAsActionDispatch(
      actionCreator(
        authActionTypes.SET_JUST_LOGGED_IN, { justLoggedIn: false},
      ),
    );
    dispatchAsActionDispatch(
      actionCreator(globalActionTypes.LOADING, false),
    );
  },
  2_000);
};

const validateDocumentToken = async () => {
  const { data } = await api.post<
  unknown, { access_token: string, }
  >('/validateDocumentToken', {}, {});

  return data.access_token;
};

const confirmForgotPassword = (
  payload: {
    password: string,
    token: string | null,
  },
  navigate: NavigateFunction,
) => async (dispatch: Dispatch<{payload: unknown, type: string, }>) => {
  dispatch(actionCreator(globalActionTypes.LOADING, true));

  try {
    await api.post('/password-reset/confirm', payload, {});
    notificationsService.success('Password successfully changed');
    navigate('/auth/login');
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    } else {
      Alert('Could not change password successfully');
    }
  } finally {
    dispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

const changePassword = (
  body: unknown,
  navigate: NavigateFunction,
) => async (dispatch: Dispatch<{payload: unknown, type: string, }>) => {
  dispatch(actionCreator(globalActionTypes.LOADING, true));

  try {
    await api.post('/change-password', body, {});
    dispatch(actionCreator(firmActionTypes.GET_FIRM_NOTIFICATION, true));
    dispatch(actionCreator(globalActionTypes.LOADING, false));
  } catch (error) {
    dispatch(actionCreator(globalActionTypes.LOADING, false));

    if (isAxiosError(error)) {
      unauthorized(error?.response?.status as number);
      Alert(error?.response?.data?.message);
    }
  } finally {
    setTimeout(() => {
      dispatch(actionCreator(firmActionTypes.GET_FIRM_NOTIFICATION, false));
      navigate('/firm/settings');
    }, 3_000);
  }
};

const logout = (
  navigate: NavigateFunction,
) => async (dispatch: Dispatch<{payload: unknown, type: string, }>) => {
  dispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    navigate('/auth/login', { replace: true });
    await api.get('/logout');
    localStorage.clear();
    sessionStorage.clear();
    dispatch(actionCreator(rootActionTypes.CLEAR_STORE, {}));
    dispatch(
      actionCreator(
        authActionTypes.SET_JUST_LOGGED_OUT, { justLoggedOut: true},
      ),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (isAxiosError(error)) {
      unauthorized(error?.response?.status || 404);
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatch(actionCreator(globalActionTypes.LOADING, false));
    dispatch(
      actionCreator(
        authActionTypes.SET_JUST_LOGGED_OUT, { justLoggedOut: false},
      ),
    );
  }
};

type ResetVerificationMethodBody = {
  phone?: string | null,
  refreshToken: string | null,
  verificationChannel: VerificationChannel,
};
const resetVerificationMethod = (
  payload: ResetVerificationMethodBody,
  navigate: NavigateFunction,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  {payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error)) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    const {data} = await api.post<
    ResetVerificationMethodBody, AuthorizeVerificationType
    >('/reset-auth-method', payload, {});
    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate,
      '/auth/verification',
      '/firm/settings',
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

type SignUpBody = {
  password: string | null,
  phone?: string | null,
  token: string | null,
  verificationChannel: VerificationChannel,
};
const signUp = (
  payload: SignUpBody,
  navigate: NavigateFunction,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  {payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error)) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  try {
    const {data} = await api.post<
    SignUpBody, AuthorizeVerificationType
    >(`/signup/confirm/${payload.token}`, payload, {});

    storeItemsInStorage<PrivacyPolicyFromStorage>(
      { privacyPolicy: 'true'},
      sessionStorage,
    );

    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate,
      '/auth/verification',
      '/firms',
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

// // copying directly from previous auth
// const impersonateByAdmin = (
//   payload: { token: string, },
// ) => api.post('/impersonateByAdmin', payload, {})
//   // eslint-disable-next-line promise/prefer-await-to-then
//   .then(() => {
//     storeItemsInStorage<LoginAsFromStorage>(
//       { loginAs: 'true' },
//       sessionStorage,
//     );
//   // eslint-disable-next-line promise/prefer-await-to-then
//   }).catch((error) => {
//     if (isAxiosError(error)) {
//       Alert(error?.response?.data?.message);
//       unauthorized(error?.response?.status as number);
//     } else {
//       Alert('Could not login as admin');
//     }
//   });

const impersonateByAdmin = async (
  payload: { token: string, },
) => {
  try {
    const result: {data: {token: string,},} = await api.post('/impersonateByAdmin', payload, {});

    storeItemsInStorage<LoginAsFromStorage>(
      { loginAs: 'true' },
      sessionStorage,
    );
    storeItemsInStorage<{access_token: string,}>(
      { access_token: result.data.token },
      sessionStorage,
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not login as admin');
    }
  }
};

// copying directly from previous auth
const verifyToken = (
  token: string | null,
  navigate: NavigateFunction,
) => api.post(`/verify-token/${token}`, {}, {})
  // eslint-disable-next-line promise/prefer-await-to-then
  .catch((error) => {
    if (isAxiosError(error)) {
      notificationsService.error(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          navigate('/auth/login');
        }, 4_000);
      }
    }
  });

const authApi = {
  changePassword,
  changePhoneNumber,
  confirmForgotPassword,
  forgotPassword,
  impersonateByAdmin,
  login,
  logout,
  resendVerification,
  resetVerificationMethod,
  setAccessToken,
  setRefreshToken,
  signUp,
  validateDocumentToken,
  verification,
  verifyToken,
};

export default authApi;
