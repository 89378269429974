import { type DocumentsPendingState } from '../../../../../store/slices';
import { type DocumentDTO } from '../../../../../types';
import { Spinner } from '../../../../Spinner';
import { Document } from './Document';
import style from './Documents.module.scss';
import EmptyFile from 'assets/icons/collapse/empty-file.svg?react';
import { type FC } from 'react';

type DocumentsProps = {
  categoryId: string,
  documents: DocumentDTO[] | null,
  documentsPendingState: DocumentsPendingState | boolean,
  onDocumentsRetry: (event: React.MouseEvent<HTMLElement>) => void,
};

export const Documents: FC<DocumentsProps> = ({
  categoryId,
  documents,
  documentsPendingState,
  onDocumentsRetry,
}) => {
  const getChildren = () => {
    if (documents) {
      return <>
        {
          documents.map((document) => <Document
            categoryId={categoryId}
            documentObject={document}
            key={document._id}
          />)
        }
      </>;
    }

    if (documentsPendingState) {
      const {
        error,
        pending,
      } = documentsPendingState as DocumentsPendingState;

      if (pending) {
        return <div className={style.documentsPendingContainer}>
          <Spinner />
        </div>;
      }

      if (error) {
        return <div className={style.documentErrorContainer}>
          <EmptyFile />
          <h3 className={style.documentErrorMessage}>
            {error}
          </h3>
          <button
            className={style.documentErrorRetryButton}
            data-test={`documents-retry-${categoryId}`}
            onClick={onDocumentsRetry}
            type='button'
          >
            <p className={style.documentErrorRetryText}>
              Retry
            </p>
          </button>
        </div>;
      }
    }

    return null;
  };

  return <div className={style.documentsContainer}>
    {getChildren()}
  </div>;
};
