/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Unticked from '../../../assets/icons/global/empty-checkbox.svg?react';
import Ticked from '../../../assets/icons/global/filled-checkbox.svg?react';
import style from './MultiSelectorOption.module.scss';
import { limitString } from 'components/Pipes/textPipes';
import { type SelectDisplayOption } from 'hooks';
import { type FC } from 'react';

type MultiSelectorOptionProps = {
  dataSelectionValue: string,
  isAllOption?: boolean,
  onClickOption: (option: SelectDisplayOption) =>
  (event: React.MouseEvent<HTMLDivElement>) => void,
  option: SelectDisplayOption,
};

const getCheckBox = (all: boolean, option: SelectDisplayOption) => {
  if (all) {
    return null;
  }

  if (option.selected) {
    return (
      <Ticked />
    );
  }

  return <Unticked />;
};

const MultiSelectorOption: FC<MultiSelectorOptionProps> = ({ dataSelectionValue,
  isAllOption,
  onClickOption,
  option }) => {
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClickOption(option)(event);
  };

  let optionName = option.name;
  if (isAllOption) {
    optionName = option.selected ? 'Unselect All' : 'Select All';
  }

  return (
    <div
      className={style.option}
      data-closest='option'
      data-test={`${dataSelectionValue}-option`}
      id={option.id}
      onClick={onClick}
      style={{ color: isAllOption ? '#005BB3' : '' }}
      title={option.name}
    >
      {
        getCheckBox(isAllOption as boolean, option)
      }
      <div className={style.optionText}>
        {limitString(optionName, 45)}
      </div>
    </div>
  );
};

export { MultiSelectorOption };
